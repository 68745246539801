import React, { Component } from 'react';
import './App.css';
import { Admin, Resource, ListGuesser } from 'react-admin';
import loopbackClient from 'react-admin-loopback';
import addUploadFeature from './addUploadFeature';
import addTeamFilter from './addTeamFilter';
import {authProvider} from './authProvider';
import Dashboard from './dashboard';
import customRoutes from './routes';
import { Layout } from './layout';
import { Login } from './auth';
import products from './products';
import spaces from './spaces';
import users from './users';
import stockinrequests from './stock_in_requests';
import stockins from './stock_ins';
import stockoutrequests from './stock_out_requests';
import spacesproducts from './spacesproducts';
import stockouts from './stock_outs';
import accounts from './accounts';
import teams from './teams';
import {appTitle,loobackApiUrl} from './config';
import englishMessages  from './i18n/en';
import chineseMessages  from './i18n/cn';
import MyLayout from './MyLayout';



const dataProvider = loopbackClient(loobackApiUrl);
const uploadCapableDataProvider = addUploadFeature(dataProvider);
const addTeamFilterProvider = addTeamFilter(uploadCapableDataProvider)

const messages = {
  cn: chineseMessages,
  en: englishMessages,
};
const i18nProvider = locale => messages[locale];

class App extends Component {
  render() {
    return (
      <div>
        <Admin 
        title={appTitle}
        loginPage={Login}
        appLayout={Layout}
        dashboard={Dashboard} 
        customRoutes={customRoutes}
        dataProvider={addTeamFilterProvider} 
        authProvider={authProvider(loobackApiUrl + '/AppUsers/login')}
        locale="cn"
        i18nProvider={i18nProvider}
        >
          {permissions => [
          	<Resource name="products" {...products} />,
        	  <Resource name="appusers" {...users} />,
            <Resource name="stockinrequests" {...stockinrequests} />,
            <Resource name="stockins" {...stockins} />,
            <Resource name="stockoutrequests" {...stockoutrequests} />,
            <Resource name="stockouts" {...stockouts} />,
            <Resource name="accounts" {...accounts} />,
            <Resource name="teams" {...teams} />,
            <Resource name="spaces"  {...spaces} />,
            <Resource name="spacesproducts"  {...spacesproducts} />
          ]}
        </Admin>
      </div>
    );
  }
}

export default App;
