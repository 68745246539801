/* eslint react/jsx-key: off */
import memoize from 'lodash/memoize';
import ProductReferenceField from '../products/ProductReferenceField';

import React from 'react';
import {
    Datagrid,
    EditButton,
    Filter,
    List,
    Responsive,
    ReferenceInput,
    AutocompleteInput,
    SimpleList,
    TextField,
    ReferenceField,
    DateField,
} from 'react-admin';

const StockOutFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <ReferenceInput source="productId" reference="products" label="resources.stockouts.fields.product" alwaysOn>
            <AutocompleteInput
                optionText={choice =>
                    `${choice.name}`
                }
            />
        </ReferenceInput>
        <ReferenceInput source="appUserId" reference="appusers" label="resources.stockouts.fields.user" alwaysOn>
            <AutocompleteInput
                optionText={choice =>
                    `${choice.username}`
                }
            />
        </ReferenceInput>
        { permissions === "Admin" || permissions === "WM" ?
            <ReferenceInput source="teamId" reference="teams" label="resources.stockoutrequests.fields.team" alwaysOn  perPage={999}>
                <AutocompleteInput
                    optionText={choice =>
                        `${choice.name}`
                    }
                />
            </ReferenceInput>
            :
            null
        }        
    </Filter>
);

const rowClick = memoize(permissions => (id, basePath, record) => {
    return permissions === 'WM'
        ? Promise.resolve('show')
        : Promise.resolve('show');
});

const StockOutList = ({ classes, permissions, ...props }) => (
    <List
        {...props}
        filters={<StockOutFilter permissions={permissions} />}
        sort={{ field: 'stockOutDate', order: 'DESC' }}
    >
        <Responsive
            small={
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record =>
                        permissions === 'Admin' ? record.role : null
                    }
                />
            }
            medium={
                <Datagrid
                    rowClick={rowClick(permissions)}
                >
                    <TextField source="id" />
                    <ProductReferenceField label="resources.stockouts.fields.product" />
                    <TextField source="qty" />
                    <DateField source="stockOutDate" />
                    <ReferenceField source="appUserId" reference="appusers" label="resources.stockouts.fields.user">
                        <TextField source="username" />
                    </ReferenceField>
                    <ReferenceField source="stockOutRequestId" reference="stockoutrequests" label="resources.stockouts.fields.stock_out_request">
                        <TextField source="id" />
                    </ReferenceField>
                    <EditButton />
                </Datagrid>
            }
        />
    </List>
);


export default StockOutList;
