/* eslint react/jsx-key: off */
import React from 'react';
import {
    Create,
    FormTab,
    SelectInput,
    ReferenceInput,
    SaveButton,
    TabbedForm,
    TextInput,
    Toolbar,
    required,
} from 'react-admin';

const SpaceEditToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="show"
            submitOnEnter={true}
        />
        {permissions === 'Admin' && (
            <SaveButton
                label='resources.actions.save_and_next'
                redirect={false}
                submitOnEnter={false}
                variant="flat"
            />
        )}
    </Toolbar>
);


const SpaceCreate = function({ permissions, ...props }) {

    return (
    <Create {...props}>
        <TabbedForm toolbar={<SpaceEditToolbar permissions={permissions} />}>
            <FormTab label="resources.spaces.form.summary" path="">
                

                <TextInput
                    source="barcode"
                    defaultValue=""
                    validate={required()}
                />
                <TextInput
                    source="name"
                    defaultValue=""
                    validate={required()}
                />

                <TextInput
                    multiline 
                    source="note"
                    defaultValue=""
                   
                />                            

            </FormTab>
        </TabbedForm>
    </Create>
    )
};

export default SpaceCreate;
