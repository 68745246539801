/* eslint react/jsx-key: off */
import React from 'react';
import {
    Create,
    FormTab,
    SaveButton,
    TabbedForm,
    TextInput,
    NumberInput,
    SelectInput,
    ReferenceInput,
    ImageInput,
    ImageField,
    Toolbar,
    required,
} from 'react-admin';

import Aside from './Aside';

const ProductEditToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="show"
            submitOnEnter={true}
        />
        {permissions === 'Admin' && (
            <SaveButton
                label='resources.actions.save_and_next'
                redirect={false}
                submitOnEnter={false}
                variant="flat"
            />
        )}
    </Toolbar>
);

const ProductCreate = ({ permissions, ...props }) => (
    <Create {...props} aside={<Aside />}>
        <TabbedForm toolbar={<ProductEditToolbar permissions={permissions} />}>
            <FormTab label="resources.products.form.summary" path="">
                <TextInput
                    source="name"
                    defaultValue=""
                    validate={required()}
                />
                {(permissions === 'WM' || permissions === 'Admin') && (
                <NumberInput
                    source="qty"
                    defaultValue=""
                    validate={required()}
                />
                )}
                {(permissions === 'WM' || permissions === 'Admin') && (
                <NumberInput
                    source="qtyInBox"
                />
                )}
                {(permissions === 'WM' || permissions === 'Admin') && (
                <NumberInput
                    source="box_weight"
                />
                )}
                {(permissions === 'WM' || permissions === 'Admin') && (
                <TextInput
                    source="box_size"
                    defaultValue=""
                />
                )}
                {(permissions === 'WM' || permissions === 'Admin') && (
                <TextInput
                    source="shelf"
                    defaultValue=""
                />   
                )}             
                <TextInput
                    source="asin"
                    defaultValue=""
                />
                <TextInput
                    source="sku"
                    defaultValue=""
                /> 
                <TextInput
                    source="iSKU"
                    defaultValue=""
                /> 

                <SelectInput source="country" allowEmpty emptyValue="" choices={[
                    { id: 'US', name: 'US' },
                    { id: 'UK', name: 'UK' },
                    { id: 'CA', name: 'CA' },
                ]} />
                <ReferenceInput disabled source="appUserId" reference="appusers" label="resources.products.fields.user" defaultValue={JSON.parse(localStorage.getItem('lbtoken')).value.userId}>
                    <SelectInput optionText="username" />
                </ReferenceInput>
                {
                    permissions === "Admin" ?
                <ReferenceInput source="teamId" reference="teams" label="resources.products.fields.team" defaultValue={JSON.parse(localStorage.getItem('lbtoken')).value.teamId}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                : 
                <ReferenceInput disabled source="teamId" reference="teams" label="resources.products.fields.team" defaultValue={JSON.parse(localStorage.getItem('lbtoken')).value.teamId}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                }

                <ImageInput source="image" accept="image/*" label="resources.products.fields.image">
                    <ImageField source="src" title="title" />
                </ImageInput>
                <TextInput
                    source="note"
                    defaultValue=""
                />
            </FormTab>

        </TabbedForm>
    </Create>
);

export default ProductCreate;
