import React from 'react';

const CurrentBoxLabelField = function({ record = {} }) { 
	
	const filename = record.boxLabel ? record.boxLabel.substr(record.boxLabel.lastIndexOf('/') + 1) : '';

    return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        <a href={record.boxLabel} target="_blank" rel="noopener noreferrer">{filename}</a>
    </div>
    )
};

CurrentBoxLabelField.defaultProps = {
    source: 'boxLabel',
    addLabel: true,
};

export default CurrentBoxLabelField;