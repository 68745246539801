/* eslint react/jsx-key: off */
import React from 'react';
import {
    Create,
    FormTab,
    SaveButton,
    TabbedForm,
    TextInput,
    SelectInput,
    ReferenceInput,
    BooleanInput,
    LongTextInput,
    DateInput,
    NumberInput,
    FileInput,
    FileField,
    ImageInput,
    ImageField,
    Toolbar,
    required,
    FormDataConsumer,
} from 'react-admin';

const StockOutRequestEditToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="list"
            submitOnEnter={true}
        />
    </Toolbar>
);

const StockOutRequestCreate = function({ permissions, ...props }) {
 const urlParams = new URLSearchParams(props.location.search);
 const productId = urlParams.get('product_id') ? parseInt(urlParams.get('product_id')) : '';
 const userId = JSON.parse(localStorage.getItem('lbtoken')).value.userId;

 return (
    <Create {...props}>
        <TabbedForm toolbar={<StockOutRequestEditToolbar permissions={permissions} />}>
            <FormTab label="resources.stockoutrequests.form.summary" path="">
                <ReferenceInput 
                    source="productId" 
                    label="resources.stockoutrequests.fields.product" 
                    reference="products" 
                    perPage={100} 
                    validate={required()} 
                    defaultValue={productId}
                >
                <SelectInput optionText="name" />
                </ReferenceInput>

                <SelectInput source="type" allowEmpty emptyValue="" choices={[
                    { id: 'FBA', name: 'resources.stockoutrequests.fba' },
                    { id: 'FBM', name: 'resources.stockoutrequests.fbm' }
                ]} validate={required()} />

                <NumberInput
                    source="qty"
                    defaultValue=""
                    validate={required()}
                />                

                <DateInput
                    source="stockOutDate"
                    defaultValue={new Date()}
                />
                <SelectInput source="shippingCompany" choices={[
                    { id: 'Fedex', name: 'Fedex' },
                    { id: 'UPS', name: 'UPS' },
                    { id: 'USPS', name: 'USPS' },
                ]} defaultValue="UPS" />

                <ImageInput source="images" label="resources.stockoutrequests.fields.images" multiple>
                    <ImageField source="src" title="title" />
                </ImageInput>

                <FormDataConsumer>
                {({ formData, ...rest }) => (formData.type == "FBM") && 
                    <FileInput source="shippingLabel" label="resources.stockoutrequests.fields.shippingLabel" validate={required()}>
                        <FileField source="src" title="title" />
                    </FileInput>
                }
                </FormDataConsumer>

                <FormDataConsumer>
                {({ formData, ...rest }) => (formData.type == "FBA") && 
                    <FileInput source="boxLabel" label="resources.stockoutrequests.fields.boxLabel">
                        <FileField source="src" title="title" />
                    </FileInput>
                }
                </FormDataConsumer>

                <FormDataConsumer>
                {({ formData, ...rest }) => (formData.type == "FBA") && 
                    <FileInput source="barcodeLabel" label="resources.stockoutrequests.fields.barcodeLabel"  validate={required()}>
                        <FileField source="src" title="title" />
                    </FileInput> 
                }
                </FormDataConsumer>

                <FormDataConsumer>
                {({ formData, ...rest }) => (formData.type == "FBA") && 
                    <TextInput
                    source="barcode"
                    />
                }
                </FormDataConsumer>

                <SelectInput source="speed" allowEmpty emptyValue="" choices={[
                    { id: 'same_day', name: 'resources.stockoutrequests.speed.same_day' },
                    { id: 'urgent', name: 'resources.stockoutrequests.speed.urgent' },
                    { id: 'normal', name: 'resources.stockoutrequests.speed.normal' },
                ]} />
                <SelectInput source="status" choices={[
                    { id: 'stocking_out', name: 'resources.stockoutrequests.tab.stocking_out' }

                    // { id: 'handling', name: 'resources.stockoutrequests.tab.handling' },
                    // { id: 'stocked_out', name: 'resources.stockoutrequests.tab.stocked_out' }
                ]} defaultValue="stocking_out" validate={required()} />
                
                <ReferenceInput source="appUserId" reference="appusers" label="resources.stockoutrequests.fields.user" validate={required()} defaultValue={userId}>
                    <SelectInput optionText="username" />
                </ReferenceInput>
                <ReferenceInput source="accountId" reference="accounts" label="resources.stockoutrequests.fields.account" validate={required()} perPage={100}>
                    <SelectInput optionText="code" />
                </ReferenceInput>
                <ReferenceInput disabled source="teamId" reference="teams" label="resources.products.fields.team" validate={required()} defaultValue={JSON.parse(localStorage.getItem('lbtoken')).value.teamId}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <LongTextInput
                    source="note"
                />
            </FormTab>

        </TabbedForm>
    </Create>
)
};

export default StockOutRequestCreate;
