/* eslint react/jsx-key: off */
import React from 'react';
import {
    Create,
    FormTab,
    SaveButton,
    TabbedForm,
    TextInput,
    Toolbar,
    required,
} from 'react-admin';

const SpaceProductEditToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="show"
            submitOnEnter={true}
        />
        {permissions === 'Admin' && (
            <SaveButton
                label='resources.actions.save_and_next'
                redirect={false}
                submitOnEnter={false}
                variant="flat"
            />
        )}
    </Toolbar>
);


const SpaceProductCreate = function({ permissions, ...props }) {

    return (
    <Create {...props}>
        <TabbedForm toolbar={<SpaceProductEditToolbar permissions={permissions} />}>
            <FormTab label="resources.SpaceProducts.form.summary" path="">
                
      
                
            </FormTab>
        </TabbedForm>
    </Create>
    )
};

export default SpaceProductCreate;
