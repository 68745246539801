/* eslint react/jsx-key: off */
import memoize from 'lodash/memoize';

import React from 'react';
import {
    Datagrid,
    EditButton,
    List,
    Filter,
    ReferenceInput,
    AutocompleteInput,
    Responsive,
    ReferenceField,
    SimpleList,
    TextField,
} from 'react-admin';


const rowClick = memoize(permissions => (id, basePath, record) => {
    return permissions === 'WM'
        ? Promise.resolve('show')
        : Promise.resolve('show');
});

const AccountFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        { permissions === "Admin" || permissions === "WM" ?
            <ReferenceInput source="teamId" reference="teams" label="resources.products.fields.team" alwaysOn>
                <AutocompleteInput
                    optionText={choice =>
                        `${choice.name}`
                    }
                />
            </ReferenceInput>
            :
            null
        }
    </Filter>
);

const AccountList = ({ classes, permissions, ...props }) => (
    <List
        {...props}
        filters={<AccountFilter permissions={permissions} />}
        sort={{ field: 'code', order: 'DESC' }}
    >
        <Responsive
            small={
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.code }
                />
            }
            medium={
                <Datagrid
                    rowClick={rowClick(permissions)}
                >
                    <TextField source="id" />
                    <TextField source="code" />
                    <TextField source="name" />
                    {permissions === 'Admin' || permissions === 'WM' ? 
                    <ReferenceField source="teamId" reference="teams" label="resources.accounts.fields.team">
                        <TextField source="name" />
                    </ReferenceField>
                     : "" }
                    <EditButton />
                </Datagrid>
            }
        />
    </List>
);


export default AccountList;
