import React, { Component }  from 'react';
import compose from 'recompose/compose';
import {translate} from 'react-admin';

const noteStyle = {
  minWidth: '90px',
}

class NoteField extends Component {
    constructor(props) {
        super(props)
        var viewMore = (props.record.note && props.record.note.length > 30) ? true : false;

        this.state = {
            viewMore: viewMore
        }
    }

    getNote = () => {
        const { record } = this.props;
        if(record.note) {
            if(record.note.length > 30) {
                return this.state.viewMore ?  record.note.substr(0, 30) : record.note
            } else {
                return record.note
            }
        } else {
            return "";
        }
    }

    viewMore = (e) => {
        e.preventDefault();

        this.setState({
            viewMore: false
        })
    }

    viewLess = (e) => {
        e.preventDefault();

        this.setState({
            viewMore: true
        })
    }

    render() {
	    return (
            <div style={noteStyle}>
	    	<span>{this.getNote()}</span><br />
            { this.state.viewMore &&
                <a href='/' onClick={this.viewMore}>more</a>
            }
            { !this.state.viewMore && this.props.record.note && this.props.record.note.length > 30 &&
                <a href='/' onClick={this.viewLess}>less</a>
            }
            </div>
	    )
	}
};

NoteField.defaultProps = {
    source: 'note',
    addLabel: true,
};

const enhance = compose(
    translate
);

export default enhance(NoteField);