import englishMessages from 'ra-language-english';

export default {
    ...englishMessages,
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            monthly_revenue: 'Monthly Revenue',
            new_orders: 'New Orders',
            pending_reviews: 'Pending Reviews',
            new_customers: 'New Customers',
            pending_orders: 'Pending Orders',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Welcome to react-admin demo',
                subtitle:
                    "This is the admin of an imaginary poster shop. Fell free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
                aor_button: 'react-admin site',
                demo_button: 'Source for this demo',
            },
        },
        menu: {
            sales: 'Sales',
            catalog: 'Catalog',
            customers: 'Customers',
        },
    },
    resources: {
        actions: {
            save_and_show: "Save and Show",
        },
        products: {
            menu: 'Products',
            name: 'Product |||| Products',
            tabs: {
                image: 'Image',
                details: 'Details',
                description: 'Description',
                reviews: 'Reviews',
            },
            form: {
                summary: 'Basic Info',
                image: 'Product Image',
                box: 'Box',
            },
            fields: {
                image: "Image",
                current_image: "Current Image",
                name: "Name",
                shelf: "Shelf",
                qty: "Current Inventory",
                qtyInBox: "Qty per Box",
                box_weight: "Box Weight(pound)",
                box_size: "Box Size",
                upcoming_qty: "Upcoming Inventory",
                asin: "ASIN",
                iSKU: "iSKU",
                country: "Marketplace",
                company: "Company",
                user: "Product Manager",
                team: "Team",
            },
            edit: {
                title: 'Product "%{title}"',
            },
            actions: {
                stock_in_request: 'Stock In Request',
                stock_out_request: 'Stock Out Request',
                product_new_shipment: 'New Shipment'
            },
            record: {
                stock_in_request: 'Stock In Request Record',
                stock_out_request: 'Stock Out Request Record'
            }
        },
        appusers: {
            menu: 'Users',
            name: 'User |||| Users',
            fields: {
                team: 'Team'
            },
            edit: {
                title: 'User "%{title}"',
            }
        },
        accounts: {
            menu: 'Seller Accounts',
            name: 'Account |||| Accounts',
            form: {
                summary: 'Account Info'
            },
            fields: {
                name: "Account Name",
                code: "Account Code",
                team: "Team",
            }
        },
        teams: {
            menu: 'Teams',
            name: 'Team |||| Teams',
            form: {
                summary: 'Team Info'
            },
            fields: {
                name: "Team Name"
            }
        },
        spaces: {
            menu: 'Spaces',
            name: 'Space |||| Spaces',
            makebarcode : 'Make a Barcode',
            form: {
                summary: 'Space Info'
            },            
            fields: {
                team: 'Space'
            },
            edit: {
                title: 'Space "%{title}"',
            }
        },  
        spacesproducts: {
            menu: 'Products in Spaces',
            name: 'Products in Spaces',
            makebarcode : 'Make a Barcode',
            form: {
                summary: 'Space Info'
            },            
            fields: {
                team: 'Space'
            },
            edit: {
                title: 'Space "%{title}"',
            }
        },                   
        stockinrequests: {
            menu: 'Stock In Requests',
            name: 'Stock In Request |||| Stock In Requests',
            form: {
                summary: 'Stock In Request Info'
            },
            fields: {
                product: "Stock In Product",
                boxes: "Boxes",
                qtyInBox: "Qty per Box",
                weightPerBox: "Weight per Box (pound)",
                qtyInTailBox: "Qty in Tail Box ",
                qty: "Total Qty",
                qtyPending: "Pending Stock In Qty",
                warehouse: "Warehouse",
                waybill: "Waybill",
                estArrivalDate: "Est. Stock In Date",
                shippingCompany: "Shipping Company",
                status: "Status",
                user: "Stockin Applicant",
                team: "Team",
            },
            actions: {
                stock_in: 'Stock In'
            },
            tab: {
                stocking_in: 'Stocking In',
                stocked_in: 'Stocked In',
                cancelled: 'Cancelled'
            }
        },
        stockins: {
            menu: ' — Stock In List',
            name: 'Stock In |||| Stock In',
            form: {
                summary: 'Stock In Info'
            },
            fields: {
                qty: 'Stock In Qty',
                warehouse: "Warehouse",
                stockInDate: 'Stock In Date',
                product: 'Stock In Product',
                stock_in_request: 'Stock In Request',
                user: 'Warehouse Manager',
                team: "Team",
            }
        },
        stockoutrequests: {
            menu: 'Stock Out Requests',
            name: 'Stock Out Request |||| Stock Out Requests',
            all: "All",            
            fba: "Amaznon FBA",
            fbm: "FBM",             
            form: {
                summary: 'Stock Out Request Info'
            },
            fields: {
                product: "Stock Out Product",
                qty: "Stock Out Total Qty",
                shippingLabel:"Shipping Label",
                warehouse: "Warehouse",
                stockOutDate: "Stock Out Date",
                shippingCompany: "Shipping Company",
                innerPage: "Contain InnerPage?",
                speed: "Shipping Speed",
                status: "Status",
                boxLabel: "Box Label",
                current_boxLabel: "Current Box Label",
                barcodeLabel: "Barcode Label",
                current_barcodeLabel: "Current Barcode Label",
                user: "Stockout Applicant",
                stockOutAccount: "Stock Out Account",
                note: "Note",
                images: "InnerPage or Product Images",
                current_images: "Current InnerPage or Product Images",
                account: "Seller Account",
                team: "Team",
            },
            actions: {
                stock_out: 'Stock Out',
                handling: 'Handling',
                download_boxbabel: "Download Box Label",
            },
            tab: {
                stocking_out: 'Stocking Out',
                handling: 'Handling',
                hold: 'Hold',
                stocked_out: 'Stocked Out',
                cancelled: 'Cancelled'
            },
            speed: {
                same_day: 'Same Day',
                urgent: 'Urgent',
                normal: 'Normal'
            }
        },
        stockouts: {
            menu: ' — Stock Out List',
            name: 'Stock Out |||| Stock Out',
            form: {
                summary: 'Stock Out Info'
            },
            fields: {
                qty: 'Stock Out Qty',
                warehouse: "Warehouse",
                stockOutDate: 'Stock Out Date',
                product: 'Stock Out Product',
                stock_out_request: 'Stock Out Request',
                user: 'Warehouse Manager',
                team: "Team",
            }
        },
    },
};