/* eslint react/jsx-key: off */
import React from 'react';
import { translate } from 'react-admin';

const QtyInBoxField = translate(({ record, translate }) => (
    <span>
        {record.qtyInBox ? record.qtyInBox + "/" + translate('resources.products.form.box') : ''}
    </span>
));

QtyInBoxField.defaultProps = {
    source: 'qtyInBox',
    addLabel: true,
};

export default QtyInBoxField;
