/* eslint react/jsx-key: off */
import React from 'react';
import {
    Create,
    AutocompleteInput,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    required,
} from 'react-admin';

const UserCreate = ({ permissions, ...props }) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput
                source="username"
                autoFocus
                validate={required()}
            />
            <TextInput
                source="email"
                validate={required()}
                type="email"
            />
            <TextInput
                source="password"
                validate={required()}
                type="password"
            />
            <AutocompleteInput
                source="role"
                choices={[
                    { id: '', name: 'None' },
                    { id: 'PM', name: 'PM' },
                    { id: 'WM', name: 'WM' },
                    { id: 'Admin', name: 'Admin' }
                ]}
                validate={required()}
            />
            <ReferenceInput source="teamId" reference="teams" label="resources.appusers.fields.team" perPage={100} >
               <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export default UserCreate;
