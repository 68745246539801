/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import { Show, SimpleShowLayout, TextField } from 'react-admin'; // eslint-disable-line import/no-unresolved


const TeamShow = ({ permissions, ...props }) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />

        </SimpleShowLayout>
    </Show>
);

TeamShow.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default TeamShow;
