/* eslint react/jsx-key: off */
import React from 'react';
import {
    Edit,
    FormTab,
    SaveButton,
    TabbedForm,
    TextInput,
    SelectInput,
    DisabledInput,
    ReferenceInput,
    DateInput,
    Toolbar,
    required,
} from 'react-admin';

const AccountEdit = function({ permissions, ...props }) {

    return (
    <Edit {...props}>
        <TabbedForm>
            <FormTab label="resources.spaces.form.summary" path="">
                
            <TextInput
                    source="barcode"
                    defaultValue=""
                    validate={required()}
                />
            <TextInput
                    source="name"
                    defaultValue=""
                    validate={required()}
                />
                    
    
                <TextInput
                    multiline 
                    source="note"
                    defaultValue=""
                  
                />                   

            </FormTab>
        </TabbedForm>
    </Edit>
    )
};

export default AccountEdit;
