import StockInIcon from '@material-ui/icons/List';
import StockInList from './StockInList';
import StockInCreate from './StockInCreate';
import StockInEdit from './StockInEdit';
import StockInShow from './StockInShow';

export default {
    list: StockInList,
    create: StockInCreate,
    edit: StockInEdit,
    show: StockInShow,
    icon: StockInIcon,
};
