/* eslint react/jsx-key: off */
import React from 'react';
import {
    Edit,
    FormTab,
    SaveButton,
    TabbedForm,
    TextInput,
    SelectInput,
    DisabledInput,
    ReferenceInput,
    DateInput,
    Toolbar,
    required,
} from 'react-admin';

const AccountEdit = function({ permissions, ...props }) {

    return (
    <Edit {...props}>
        <TabbedForm>
            <FormTab label="resources.accounts.form.summary" path="">
                
                <TextInput
                    source="name"
                    defaultValue=""
                    validate={required()}
                />
                <TextInput
                    source="code"
                    defaultValue=""
                    validate={required()}
                />

            </FormTab>
        </TabbedForm>
    </Edit>
    )
};

export default AccountEdit;
