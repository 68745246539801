import StockInRequestIcon from '@material-ui/icons/MoveToInbox';
import StockInRequestList from './StockInRequestList';
import StockInRequestCreate from './StockInRequestCreate';
import StockInRequestEdit from './StockInRequestEdit';
import StockInRequestShow from './StockInRequestShow';

export default {
    list: StockInRequestList,
    create: StockInRequestCreate,
    edit: StockInRequestEdit,
    show: StockInRequestShow,
    icon: StockInRequestIcon,
};
