/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import { Show, SimpleShowLayout, TextField, DateField, ReferenceField } from 'react-admin'; // eslint-disable-line import/no-unresolved


const StockInRequestShow = ({ permissions, ...props }) => (
    <Show {...props}>
        <SimpleShowLayout>
            <ReferenceField label="Product" source="productId" reference="products">
                <TextField source="name" />
            </ReferenceField>
            
            <TextField source="qty" />
            <TextField source="qtyPending" />
            <TextField source="waybill" />
            <DateField source="estArrivalDate" />
            <TextField source="shippingCompany" />
            <TextField source="status" />

            <ReferenceField label="User" source="appUserId" reference="appusers">
                <TextField source="username" />
            </ReferenceField>
            <ReferenceField label="Team" source="teamId" reference="teams">
                <TextField source="name" />
            </ReferenceField>


        </SimpleShowLayout>
    </Show>
);

StockInRequestShow.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default StockInRequestShow;
