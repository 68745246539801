/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Edit,
    SaveButton,
    Toolbar,
    TabbedForm,
    FormTab,
    TextInput,
    SelectInput,
    ReferenceInput,
    BooleanInput,
    LongTextInput,
    DateInput,
    NumberInput,
    FileInput,
    FileField,
    ImageInput,
    ImageField,
    required,
    FormDataConsumer,
} from 'react-admin';
import CurrentBoxLabelField from './CurrentBoxLabelField';
import CurrentImageListField from './CurrentImageListField';
import CurrentBarcodeLabelField from './CurrentBarcodeLabelField';
import CurrentShippingLabelField from './CurrentShippingLabelField';


const StockOutRequestEditToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="list"
            submitOnEnter={true}
        />
    </Toolbar>
);

const StockOutRequestEdit = function({ permissions, ...props }) {

 return (
    <Edit {...props}>
        <TabbedForm toolbar={<StockOutRequestEditToolbar permissions={permissions} />}>
            <FormTab label="resources.stockoutrequests.form.summary" path="">
                <ReferenceInput 
                    source="productId" 
                    label="resources.stockoutrequests.fields.product" 
                    reference="products" 
                    perPage={100} 
                    validate={required()} 
                >
                <SelectInput optionText="name" />
                </ReferenceInput>
                <SelectInput source="type" allowEmpty emptyValue="" choices={[
                    { id: 'FBA', name: 'resources.stockoutrequests.fba' },
                    { id: 'FBM', name: 'resources.stockoutrequests.fbm' }
                ]} validate={required()} />                
                <NumberInput
                    source="qty"
                    defaultValue=""
                    validate={required()}
                />

                <DateInput
                    source="stockOutDate"
                    defaultValue={new Date()}
                />
                <SelectInput source="shippingCompany" choices={[
                    { id: 'Fedex', name: 'Fedex' },
                    { id: 'UPS', name: 'UPS' },
                    { id: 'USPS', name: 'USPS' },
                ]} />

                <ImageInput source="images" label="resources.stockoutrequests.fields.images" multiple>
                    <ImageField source="src" title="title" />
                </ImageInput>
                <CurrentImageListField label="resources.stockoutrequests.fields.current_images" />
                <FormDataConsumer>
                {({ formData, ...rest }) => (formData.type == "FBM") && 
                    <FileInput source="shippingLabel" label="resources.stockoutrequests.fields.shippingLabel" {...rest}>
                        <FileField source="src" title="title" />
                    </FileInput>
                }
                </FormDataConsumer>
                <FormDataConsumer>
                {({ formData, ...rest }) => (formData.type == "FBM") && 
                    <CurrentShippingLabelField label="resources.stockoutrequests.fields.current_shippingLabel" {...rest} />}
                </FormDataConsumer>

                <FormDataConsumer>
                {({ formData, ...rest }) => (formData.type == "FBA") && 
                    <FileInput source="boxLabel" label="resources.stockoutrequests.fields.boxLabel" {...rest}>
                        <FileField source="src" title="title" />
                    </FileInput>
                }
                </FormDataConsumer>
                <FormDataConsumer>
                {({ formData, ...rest }) => (formData.type == "FBA") && 
                    <CurrentBoxLabelField label="resources.stockoutrequests.fields.current_boxLabel" {...rest} />}
                </FormDataConsumer>

                <FormDataConsumer>
                {({ formData, ...rest }) => (formData.type == "FBA") && 
                <FileInput source="barcodeLabel" label="resources.stockoutrequests.fields.barcodeLabel" {...rest}>
                    <FileField source="src" title="title" />
                </FileInput>}
                </FormDataConsumer>
                <FormDataConsumer>
                {({ formData, ...rest }) => (formData.type == "FBA") && 
                    <CurrentBarcodeLabelField label="resources.stockoutrequests.fields.current_barcodeLabel" {...rest} />}
                </FormDataConsumer>  
                <FormDataConsumer>
                {({ formData, ...rest }) => (formData.type == "FBA") && 
                <TextInput
                    source="barcode" {...rest}
                />}
                </FormDataConsumer>                

                <SelectInput source="speed" allowEmpty emptyValue="" choices={[
                    { id: 'same_day', name: 'resources.stockoutrequests.speed.same_day' },
                    { id: 'urgent', name: 'resources.stockoutrequests.speed.urgent' },
                    { id: 'normal', name: 'resources.stockoutrequests.speed.normal' },
                ]} />
                {permissions === 'Admin' || permissions === 'WM' ? 
                <SelectInput source="status" choices={[
                    { id: 'stocking_out', name: 'resources.stockoutrequests.tab.stocking_out' },
                    { id: 'handling', name: 'resources.stockoutrequests.tab.handling' },
                    { id: 'hold', name: 'resources.stockoutrequests.tab.hold' },
                    { id: 'stocked_out', name: 'resources.stockoutrequests.tab.stocked_out' }
                ]} validate={required()} />
                : "" }
                <ReferenceInput source="appUserId" reference="appusers" label="resources.stockoutrequests.fields.user" validate={required()} defaultValue={JSON.parse(localStorage.getItem('lbtoken')).value.userId}>
                    <SelectInput optionText="username" />
                </ReferenceInput>
                <ReferenceInput source="accountId" reference="accounts" label="resources.stockoutrequests.fields.account" validate={required()} perPage={100}>
                    <SelectInput optionText="code" />
                </ReferenceInput>
                <LongTextInput
                    source="note"
                />
            </FormTab>

        </TabbedForm>
    </Edit>
    );
}

StockOutRequestEdit.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default StockOutRequestEdit;
