import React, { Component }  from 'react';
import compose from 'recompose/compose';
import {translate} from 'react-admin';

const noteStyle = {
  minWidth: '90px',
}

class WaybillField extends Component {
    constructor(props) {
        super(props)
        var viewMore = (props.record.waybill && props.record.waybill.length > 30) ? true : false;

        this.state = {
            viewMore: viewMore
        }
    }

    getWaybill = () => {
        const { record } = this.props;
        if(record.waybill) {
            if(record.waybill.length > 30) {
                return this.state.viewMore ?  record.waybill.substr(0, 30) : record.waybill
            } else {
                return record.waybill
            }
        } else {
            return "";
        }
    }

    viewMore = (e) => {
        e.preventDefault();

        this.setState({
            viewMore: false
        })
    }

    viewLess = (e) => {
        e.preventDefault();

        this.setState({
            viewMore: true
        })
    }

    render() {
	    return (
            <div style={noteStyle}>
	    	<span>{this.getWaybill()}</span><br />
            { this.state.viewMore &&
                <a href='/' onClick={this.viewMore}>more</a>
            }
            { !this.state.viewMore && this.props.record.waybill && this.props.record.waybill.length > 30 &&
                <a href='/' onClick={this.viewLess}>less</a>
            }
            </div>
	    )
	}
};

WaybillField.defaultProps = {
    source: 'waybill',
    addLabel: true,
};

const enhance = compose(
    translate
);

export default enhance(WaybillField);