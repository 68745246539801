import React from 'react';

const CurrentImageListField = function({ record = {} }) { 
	const getImagelists = record => {
		var output = "";
		if(record.images && record.images.length > 0) {
			output += "<ul>";
			record.images.split(",").forEach(img => {
	        	output += '<li><a href="'+img+'" target="_blank" rel="noopener noreferrer">'+img.substr(img.lastIndexOf('/') + 1)+'</a></li>';
	        })
	        output += "</ul>";
        }
        return output;
	}

	var output = getImagelists(record)

    return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }} dangerouslySetInnerHTML={{__html: output}} />
    )
};

CurrentImageListField.defaultProps = {
    source: 'images',
    addLabel: true,
};

export default CurrentImageListField;