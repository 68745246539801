/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Edit,
    FormTab,
    SelectInput,
    ReferenceInput,
    ImageInput,
    ImageField,
    TabbedForm,
    TextInput,
    NumberInput,
    Toolbar,
    SaveButton,
    required,
} from 'react-admin';

import ProductTitle from './ProductTitle';

const ProductEditToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="list"
            submitOnEnter={true}
        />
    </Toolbar>
);

const ProductEdit = ({ permissions, ...props }) => (
    <Edit title={<ProductTitle />} {...props}>
        <TabbedForm toolbar={<ProductEditToolbar permissions={permissions} />}>
            <FormTab label="resources.products.form.summary" path="">
                
                <TextInput
                    source="name"
                    defaultValue=""
                    validate={required()}
                />

                {(permissions === 'WM' || permissions === 'Admin') && (
                <NumberInput
                    source="qty"
                    defaultValue=""
                    validate={required()}
                />
                )}
                {(permissions === 'WM' || permissions === 'Admin') && (
                <TextInput
                    source="shelf"
                    defaultValue=""
                />   
                )}             
                {(permissions === 'WM' || permissions === 'Admin') && (
                <NumberInput
                    source="qty_stl"
                    defaultValue=""
                />
                )}
                {(permissions === 'WM' || permissions === 'Admin') && (
                <NumberInput
                    source="qtyInBox"
                />
                )}
                {(permissions === 'WM' || permissions === 'Admin') && (
                <NumberInput
                    source="box_weight"
                />
                )}
                {(permissions === 'WM' || permissions === 'Admin') && (
                <TextInput
                    source="box_size"
                    defaultValue=""
                />
                )}
                <TextInput
                    source="asin"
                    defaultValue=""
                />
                <TextInput
                    source="sku"
                    defaultValue=""
                />          
                <TextInput
                    source="iSKU"
                    defaultValue=""
                />  
           
                <SelectInput source="country" allowEmpty emptyValue="" choices={[
                    { id: 'US', name: 'US' },
                    { id: 'UK', name: 'UK' },
                    { id: 'CA', name: 'CA' },
                ]} />
                <ReferenceInput source="appUserId" allowEmpty emptyValue="" reference="appusers" label="resources.products.fields.user">
                    <SelectInput optionText="username" />
                </ReferenceInput>
                <ImageInput source="image" accept="image/*" label="resources.products.fields.image">
                    <ImageField source="src" title="title" />
                </ImageInput>
                <ImageField source="image" title="title" label="resources.products.fields.current_image"/>
                <TextInput
                    source="note"
                    defaultValue=""
                />                
            </FormTab>
        </TabbedForm>
    </Edit>
);

ProductEdit.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default ProductEdit;