import chineseMessages from 'ra-language-chinese';

export default {
    ...chineseMessages,
    pos: {
        search: '搜索',
        configuration: '配置',
        language: '语言',
        theme: {
            name: '主题',
            light: '明亮',
            dark: '灰暗',
        },
        dashboard: {
            monthly_revenue: 'Monthly Revenue',
            new_orders: 'New Orders',
            pending_reviews: 'Pending Reviews',
            new_customers: 'New Customers',
            pending_orders: 'Pending Orders',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Welcome to react-admin demo',
                subtitle:
                    "This is the admin of an imaginary poster shop. Fell free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
                aor_button: 'react-admin site',
                demo_button: 'Source for this demo',
            },
        },
        menu: {
            sales: 'Sales',
            catalog: 'Catalog',
            customers: 'Customers',
        },
    },
    resources: {
        actions: { 
            save_and_show: "保存",
        },
        products: {
            menu: '产品列表',
            name: '产品 |||| 产品',
            tabs: {
                image: 'Image',
                details: 'Details',
                description: 'Description',
                reviews: 'Reviews',
            },
            form: {
                summary: '产品信息',
                image: '产品图片',
                box: '箱',
            },
            fields: {
                image: "产品图片",
                current_image: "当前图片",
                name: "产品名称",
                shelf: "货架",
                qty: "当前库存数量",
                qtyInBox: "每箱产品数量",
                box_weight: "一览重量(磅)",
                box_size: "箱子尺寸",
                upcoming_qty: "未入库数量",
                asin: "ASIN",
                iSKU: "iSKU",
                country: "卖场",
                company: "公司",
                user: "产品经理",
                team: "团队",
            },
            edit: {
                title: '产品 "%{title}"',
            },
            actions: {
                stock_in_request: '入库申请',
                stock_out_request: '发货申请'
            },
            record: {
                stock_in_request: '入库申请记录',
                stock_out_request: '发货申请记录'
            }
        },
        appusers: {
            menu: '用户管理',
            name: '用户 |||| 用户',
            fields: {
                team: '团队'
            },
            edit: {
                title: '用户 "%{title}"',
            }
        },
        accounts: {
            menu: '发货账户管理',
            name: '账户 |||| 账户',
            form: {
                summary: '账户信息'
            },
            fields: {
                name: "账户名称",
                code: "账户代码",
                team: "团队",
            }
        },
        teams: {
            menu: '团队管理',
            name: '团队 |||| 团队',
            form: {
                summary: '团队信息'
            },
            fields: {
                name: "团队名称"
            }
        },
        spaces: {
            menu: 'Spaces',
            name: 'Space |||| Spaces',
            makebarcode : 'Make a Barcode',
            form: {
                summary: 'Space Info'
            },            
            fields: {
                team: 'Space'
            },
            edit: {
                title: 'Space "%{title}"',
            }
        },  
        spacesproducts: {
            menu: 'Products in Spaces',
            name: 'Products in Spaces',
            makebarcode : 'Make a Barcode',
            form: {
                summary: 'Space Info'
            },            
            fields: {
                team: 'Space'
            },
            edit: {
                title: 'Space "%{title}"',
            }
        },             
        stockinrequests: {
            menu: '入库申请',
            name: '入库申请 |||| 入库申请',
            form: {
                summary: '入库申请信息'
            },
            fields: {
                product: "入库产品",
                boxes: "箱数",
                qtyInBox: "每箱产品数量",
                weightPerBox: "每箱重量（Pound）",
                qtyInTailBox: "尾箱产品数量",
                qty: "产品总数量",
                qtyPending: "未入库数量",
                warehouse: "仓库",
                waybill: "运单号",
                estArrivalDate: "预计入库时间",
                shippingCompany: "邮递公司",
                status: "状态",
                user: "入库申请人",
                team: "团队",
            },
            actions: {
                stock_in: '入库',
            },
            tab: {
                stocking_in: '未入库',
                stocked_in: '已入库',
                cancelled: '已终止',
                hold: '保持'
            },
        },
        stockins: {
            menu: ' — 入库清单',
            name: '入库 |||| 入库',
            form: {
                summary: '入库信息'
            },
            fields: {
                qty: '入库数量',
                warehouse: "仓库",
                stockInDate: '入库时间',
                product: '入库产品',
                stock_in_request: '入库申请',
                user: '仓管员',
                team: "团队",
            }
        },
        stockoutrequests: {
            all: "All",                   
            fba: "Amaznon FBA",
            fbm: "FBM",                     
            menu: '发货申请',
            name: '发货申请 |||| 发货申请',
            form: {
                summary: '发货申请信息'
            },
            fields: {
                product: "发货产品",
                qty: "发货数量",
                shippingLabel:"Shipping Label",                
                warehouse: "仓库",
                stockOutDate: "发货时间",
                shippingCompany: "邮递公司",
                innerPage: "含有内页？",
                speed: "发货速度",
                status: "状态",
                boxLabel: "箱标",
                current_boxLabel: "当前箱标",
                barcodeLabel: "Barcode Label",
                current_barcodeLabel: "Current Barcode Label",                
                user: "发货申请人",
                stockOutAccount: "发货账户",
                note: "备注",
                images: "内页或产品图片",
                current_images: "当前内页或产品图片",
                account: "发货账户",
                team: "团队",
            },
            actions: {
                stock_out: '发货',
                handling: '处理',
                download_boxbabel: "下载箱标",
            },
            tab: {
                stocking_out: '未发货',
                handling: '处理中',
                hold: '保持',
                stocked_out: '已发货',
                cancelled: '已终止'
            },
            speed: {
                same_day: '当日发出',
                urgent: '紧急',
                normal: '普通'
            }
        },
        stockouts: {
            menu: ' — 发货清单',
            name: '发货 |||| 发货',
            form: {
                summary: '发货信息'
            },
            fields: {
                qty: '发货数量',
                warehouse: "仓库",
                stockOutDate: '发货时间',
                product: '发货产品',
                stock_out_request: '发货申请',
                user: '仓管员',
                team: "团队",
            }
        },
    },
};