/* eslint react/jsx-key: off */
import memoize from 'lodash/memoize';
import MakeSpaceBarcodeButton from './MakeSpaceBarcodeButton';

import React from 'react';
import {
    Datagrid,
    EditButton,
    List,
    Filter,
    ReferenceInput,
    AutocompleteInput,
    Responsive,
    ReferenceField,
    SimpleList,
    TextField,
} from 'react-admin';


const rowClick = memoize(permissions => (id, basePath, record) => {
    return permissions === 'WM'
        ? Promise.resolve('show')
        : Promise.resolve('show');
});

const SpaceFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
    </Filter>
);

const SpaceList = ({ classes, permissions, ...props }) => (
    <List
        {...props}
        filters={<SpaceFilter permissions={permissions} />}
        sort={{ field: 'id', order: 'DESC' }}
    >
        <Responsive
            small={
                <SimpleList
                    primaryText={record => record.name}
                />
            }
            medium={
                <Datagrid
                    rowClick={rowClick(permissions)}
                >
                    <TextField source="barcode" />
                    <TextField source="name" />
                    <MakeSpaceBarcodeButton />
                    <EditButton />
                </Datagrid>
            }
        />
    </List>
);


export default SpaceList;
