/* eslint react/jsx-key: off */
import memoize from 'lodash/memoize';
import ProductReferenceField from '../products/ProductReferenceField';

import React from 'react';
import {
    Datagrid,
    EditButton,
    List,
    Responsive,
    ReferenceInput,
    SimpleList,
    ReferenceField,
    TextField,
} from 'react-admin';


const rowClick = memoize(permissions => (id, basePath, record) => {
    return permissions === 'WM'
        ? Promise.resolve('show')
        : Promise.resolve('show');
});

const SpaceProductList = ({ classes, permissions, ...props }) => (
    <List
        {...props}
        sort={{ field: 'id', order: 'DESC' }}
    >
        <Responsive
            small={
                <SimpleList
                    primaryText={record => record.productId}
                />
            }
            medium={
                <Datagrid
                    rowClick={rowClick(permissions)}
                >
                    <TextField source="id" />
                    <ProductReferenceField />               
                    <ReferenceField source="productId" reference="products" label="ASIN" >
                        <TextField source="asin" />
                    </ReferenceField>   
                    <ReferenceField source="spaceId" reference="spaces" >
                        <TextField source="name" />
                    </ReferenceField>   
                    <TextField source="qty" />
           
                </Datagrid>
            }
        />
    </List>
);


export default SpaceProductList;
