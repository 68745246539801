const addTeamFilter = requestHandler => (type, resource, params) => {

	// const team = storage.load('lbteam');
	var teamId = JSON.parse(localStorage.getItem('lbtoken')).value.teamId;
	var role = JSON.parse(localStorage.getItem('lbrole')).value;

	if ((type === 'GET_LIST') && role === 'PM' && (resource === 'appusers' || resource === 'products' || resource === 'stockinrequests' || resource === 'stockins' || resource === 'stockoutrequests' || resource === 'stockouts' || resource === 'accounts') ) {

		return requestHandler(type, resource, {
            ...params,
            filter: {
            	...params.filter,
       			teamId: teamId         
            },
        });



	}

    // for other request types and resources, fall back to the default request handler
    return requestHandler(type, resource, params);

}

export default addTeamFilter;