import React from 'react';
import { Show, EditButton, TextField, ReferenceManyField, Datagrid, DateField, ReferenceField, Pagination } from 'react-admin';

const StockOutEmbedded = ({ permissions, ...props }) => (
    /* Passing " " as title disables the custom title */
    <Show {...props}>
        <ReferenceManyField
                reference="stockouts"
                target="stockOutRequestId"
                pagination={<Pagination />}
            >
                <Datagrid>
                    <TextField source="id" />
                    <ReferenceField source="appUserId" reference="appusers" label="resources.stockouts.fields.user" >
                        <TextField source="username" />
                    </ReferenceField>
                    <TextField source="qty" />
                    <DateField source="stockOutDate" />
                    {permissions === 'WM' ? <EditButton /> : "" }
                </Datagrid>
        </ReferenceManyField>
    </Show>
);

StockOutEmbedded.propTypes = Show.propTypes;

export default StockOutEmbedded;
