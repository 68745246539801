/* eslint react/jsx-key: off */
import React from 'react';
import {
    Edit,
    FormTab,
    SaveButton,
    TabbedForm,
    TextInput,
    SelectInput,
    DisabledInput,
    ReferenceInput,
    DateInput,
    Toolbar,
    required,
} from 'react-admin';

const SpaceProductEdit = function({ permissions, ...props }) {

    return (
    <Edit {...props}>
        <TabbedForm>
            <FormTab>
                

            </FormTab>
        </TabbedForm>
    </Edit>
    )
};

export default SpaceProductEdit;
