import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import {appTitle} from '../config';

export default () => (
    <Card>
        <CardHeader title={"Welcome to " + appTitle} />
        <CardContent></CardContent>
    </Card>
);