import SpaceIcon from '@material-ui/icons/List';
import SpaceList from './SpaceList';
import SpaceCreate from './SpaceCreate';
import SpaceEdit from './SpaceEdit';
import SpaceShow from './SpaceShow';

export default {
    list: SpaceList,
    create: SpaceCreate,
    edit: SpaceEdit,
    show: SpaceShow,
    icon: SpaceIcon,
};
