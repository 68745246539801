// in ./MovetoStockInButton.js

import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, crudUpdateMany } from 'react-admin';
import moment from 'moment';

class MovetoStockInButton extends Component {
    state = {
        isOpen: false,
    }

    handleClick = () => {
        this.setState({ isOpen: true });
    }

    handleDialogClose = () => {
        this.setState({ isOpen: false });
    };

    handleConfirm = () => {
        const { basePath, crudUpdateMany, resource, selectedIds } = this.props;
        crudUpdateMany(resource, selectedIds, { bulkRequest: "MovetoStockIn", changeToStockIn : true , stockInDate : moment().format('YYYY-MM-DD HH:mm:ss')}, basePath)
        this.setState({ isOpen: true });
    };

    render() {
        return (
            <Fragment>
                <Button label="Move To Stocked In" onClick={this.handleClick} />
                <Confirm
                    isOpen={this.state.isOpen}
                    title="Move to Stocked In for Selected Items"
                    content="Do you want to move all selected items as Stocked In?"
                    onConfirm={this.handleConfirm}
                    onClose={this.handleDialogClose}
                />
            </Fragment>
        );
    }
}

export default connect(undefined, { crudUpdateMany })(MovetoStockInButton);

