/* eslint react/jsx-key: off */
import ProductIcon from '@material-ui/icons/Storage';
// import StockInListEmbedded from './StockInListEmbedded';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import compose from 'recompose/compose';
import HandlingButton from './HandlingButton';
import StockOutButton from './StockOutButton';
import ProductReferenceField from '../products/ProductReferenceField';
import StockOutEmbedded from './StockOutEmbedded';
import DownloadBoxLabelField from './DownloadBoxLabelField';
import DownloadShippingLabelField from './DownloadShippingLabelField';
import DownloadStockoutLabelField from './DownloadStockoutLabelField';
import StatusField from './StatusField';
import NoteField from './NoteField';
import { BulkDeleteButton } from 'react-admin';
import MovetoHandlingButton from './MovetoHandlingButton';
import MovetoHoldButton from './MovetoHoldButton';
import MovetoStockOutButton from './MovetoStockOutButton';


import React, { Fragment } from 'react';
import {
    Datagrid,
    EditButton,
    Filter,
    List,
    Responsive,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    TextField,
    NumberField,
    ReferenceField,
    DateField,
    translate,
} from 'react-admin';

import MobileGrid from '../layout/MobileGrid';

export const UserIcon = ProductIcon;

const StockOutRequestBulkActionButtons = ({ permissions, ...props }) => (
    <Fragment>
        { permissions === "Admin" || permissions === 'WM' ? <MovetoStockOutButton label="StockOut" {...props} /> : null} 
        { permissions === "Admin" || permissions === 'WM' ? <MovetoHandlingButton label="Handling" {...props} /> : null}
        { permissions === "Admin" || permissions === 'WM' ? <MovetoHoldButton label="Hold" {...props} /> : null}
        {/* Add the default bulk delete action */}
        { permissions === "Admin" || permissions === 'WM' ? <BulkDeleteButton {...props} /> : null}
    </Fragment>
);


const StockOutRequestFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <ReferenceInput source="productId" reference="products" label="resources.stockoutrequests.fields.product" alwaysOn>
            <AutocompleteInput
                optionText={choice =>
                    `${choice.name}`
                }
            />
        </ReferenceInput>
        <ReferenceInput source="appUserId" reference="appusers" label="resources.stockoutrequests.fields.user" alwaysOn>
            <AutocompleteInput
                optionText={choice =>
                    `${choice.username}`
                }
            />
        </ReferenceInput>
        <SelectInput source="speed" alwaysOn choices={[
                    { id: 'same_day', name: 'resources.stockoutrequests.speed.same_day' },
                    { id: 'urgent', name: 'resources.stockoutrequests.speed.urgent' },
                    { id: 'normal', name: 'resources.stockoutrequests.speed.normal' },
                ]}
        />
        { permissions === "Admin" || permissions === "WM" ?
            <ReferenceInput source="teamId" reference="teams" label="resources.stockoutrequests.fields.team" alwaysOn  perPage={999}>
                <AutocompleteInput
                    optionText={choice =>
                        `${choice.name}`
                    }
                />
            </ReferenceInput>
            :
            null
        }
    </Filter>
);

class TabbedDatagrid extends React.Component {

    state = { stocking_out: [], handling: [], stocked_out: [], cancelled: [] };

    static getDerivedStateFromProps(props, state) {
        if (props.ids !== state[props.filterValues.status]) {
            return { ...state, [props.filterValues.status]: props.ids };
        }
        return null;
    }

    handleChange = (event, value) => {
        const { filterValues, setFilters } = this.props;
        setFilters({ ...filterValues, status: value });
    };

    render() {

        const { classes, filterValues, translate, permissions, ...props } = this.props;

        return (

            <Fragment>
                <Tabs
                    fullWidth
                    centered
                    value={filterValues.status}
                    indicatorColor="primary"
                    onChange={this.handleChange}
                >
                        <Tab
                            key="stocking_out"
                            label={translate(`resources.stockoutrequests.tab.stocking_out`)}
                            value="stocking_out"
                        />
                        <Tab
                            key="handling"
                            label={translate(`resources.stockoutrequests.tab.handling`)}
                            value="handling"
                        />
                        <Tab
                            key="hold"
                            label={translate(`resources.stockoutrequests.tab.hold`)}
                            value="hold"
                        />                        
                        <Tab
                            key="stocked_out"
                            label={translate(`resources.stockoutrequests.tab.stocked_out`)}
                            value="stocked_out"
                        />
                        {/* <Tab
                            key="cancelled"
                            label={translate(`resources.stockoutrequests.tab.cancelled`)}
                            value="cancelled"
                        /> */}
                </Tabs>
                <Divider />
                <Responsive
                    xsmall={
                        <MobileGrid
                            {...props}
                            ids={this.state[filterValues.status]}
                        />
                    }
                    medium={
                        <div>
                            {filterValues.status === 'stocking_out' && (
                                <Datagrid {...props} 
                                    ids={this.state.stocking_out} 
                                    expand={<StockOutEmbedded permissions={permissions} />}
                                >
                                    <TextField source="id" />
                                    {permissions === 'Admin' || permissions === 'WM' ? 
                                    <ReferenceField source="teamId" reference="teams" label="resources.stockoutrequests.fields.team">
                                        <TextField source="name" />
                                    </ReferenceField>
                                     : "" }
                                    <ReferenceField source="appUserId" reference="appusers" label="resources.stockoutrequests.fields.user">
                                        <TextField source="username" />
                                    </ReferenceField>
                                    <ProductReferenceField label="resources.stockoutrequests.fields.product" />
                                    <StatusField />
                                    <TextField source="type" />
                                    <NumberField source="qty" />
                                    <DateField source="stockOutDate" />
                                    <TextField source="shippingCompany" />
                                    {(filterValues.type === 'FBA' || filterValues.type === undefined) && (
                                        <TextField source="barcode" />
                                    )}    
                                    {(filterValues.type === 'FBA' || filterValues.type === undefined) && (
                                        <DownloadBoxLabelField />
                                    )}
                                    {(filterValues.type === 'FBM' || filterValues.type === undefined) && (
                                        <DownloadShippingLabelField />
                                    )}
                                    <DownloadStockoutLabelField />
                                    <TextField source="speed" />
                                    <ReferenceField source="accountId" reference="accounts" label="resources.stockoutrequests.fields.account" allowEmpty={true}>
                                        <TextField source="code" />
                                    </ReferenceField>
                                    <NoteField />
                                    <EditButton />
                                    {permissions === 'WM' ? <HandlingButton /> : "" }
                                    {permissions === 'WM' ? <StockOutButton /> : "" }
                                </Datagrid>
                                
                            )}
                            {filterValues.status === 'handling' && (
                                <Datagrid {...props} 
                                    ids={this.state.handling} 
                                    expand={<StockOutEmbedded permissions={permissions} />}
                                >
                                    <TextField source="id" />
                                    {permissions === 'Admin' || permissions === 'WM' ? 
                                    <ReferenceField source="teamId" reference="teams" label="resources.stockoutrequests.fields.team">
                                        <TextField source="name" />
                                    </ReferenceField>
                                     : "" }
                                    <ReferenceField source="appUserId" reference="appusers" label="resources.stockoutrequests.fields.user">
                                        <TextField source="username" />
                                    </ReferenceField>
                                    <ProductReferenceField label="resources.stockoutrequests.fields.product" />
                                    <StatusField />
                                    <TextField source="type" />
                                    <NumberField source="qty" />
                                    <DateField source="stockOutDate" />
                                    <TextField source="shippingCompany" />

                                    {(filterValues.type === 'FBA' || filterValues.type === undefined) && (
                                        <TextField source="barcode" />
                                    )}    
                                    {(filterValues.type === 'FBA' || filterValues.type === undefined) && (
                                        <DownloadBoxLabelField />
                                    )}
                                    {(filterValues.type === 'FBM' || filterValues.type === undefined) && (
                                        <DownloadShippingLabelField />
                                    )}

                                    <DownloadStockoutLabelField />
                                    <TextField source="speed" />
                                    <ReferenceField source="accountId" reference="accounts" label="resources.stockoutrequests.fields.account" allowEmpty={true}>
                                        <TextField source="code" />
                                    </ReferenceField>
                                    <NoteField />
                                    <EditButton />
                                    {permissions === 'WM' ? <StockOutButton /> : "" }
                                </Datagrid>
                            )}

                            {filterValues.status === 'hold' && (
                                <Datagrid {...props} 
                                    ids={this.state.hold} 
                                    expand={<StockOutEmbedded permissions={permissions} />}
                                >
                                    <TextField source="id" />
                                    {permissions === 'Admin' || permissions === 'WM' ? 
                                    <ReferenceField source="teamId" reference="teams" label="resources.stockoutrequests.fields.team">
                                        <TextField source="name" />
                                    </ReferenceField>
                                     : "" }
                                    <ReferenceField source="appUserId" reference="appusers" label="resources.stockoutrequests.fields.user">
                                        <TextField source="username" />
                                    </ReferenceField>
                                    <ProductReferenceField label="resources.stockoutrequests.fields.product" />
                                    <StatusField />
                                    <TextField source="type" />
                                    <NumberField source="qty" />
                                    <DateField source="stockOutDate" />
                                    <TextField source="shippingCompany" />

                                    {(filterValues.type === 'FBA' || filterValues.type === undefined) && (
                                        <TextField source="barcode" />
                                    )}    
                                    {(filterValues.type === 'FBA' || filterValues.type === undefined) && (
                                        <DownloadBoxLabelField />
                                    )}
                                    {(filterValues.type === 'FBM' || filterValues.type === undefined) && (
                                        <DownloadShippingLabelField />
                                    )}

                                    <DownloadStockoutLabelField />
                                    <TextField source="speed" />
                                    <ReferenceField source="accountId" reference="accounts" label="resources.stockoutrequests.fields.account" allowEmpty={true}>
                                        <TextField source="code" />
                                    </ReferenceField>
                                    <NoteField />
                                    <EditButton />
                                    {permissions === 'WM' ? <StockOutButton /> : "" }
                                </Datagrid>
                            )}

                            {filterValues.status === 'stocked_out' && (
                                <Datagrid {...props} 
                                    ids={this.state.stocked_out} 
                                    expand={<StockOutEmbedded permissions={permissions} />}
                                >
                                    <TextField source="id" />
                                    {permissions === 'Admin' || permissions === 'WM' ? 
                                    <ReferenceField source="teamId" reference="teams" label="resources.stockoutrequests.fields.team">
                                        <TextField source="name" />
                                    </ReferenceField>
                                     : "" }
                                    <ReferenceField source="appUserId" reference="appusers" label="resources.stockoutrequests.fields.user">
                                        <TextField source="username" />
                                    </ReferenceField>
                                    <ProductReferenceField label="resources.stockoutrequests.fields.product" />
                                    <StatusField />
                                    <TextField source="type" />
                                    <NumberField source="qty" />
                                    <DateField source="stockOutDate" />
                                    <TextField source="shippingCompany" />
                                    {(filterValues.type === 'FBA' || filterValues.type === undefined) && (
                                        <TextField source="barcode" />
                                    )}    
                                    {(filterValues.type === 'FBA' || filterValues.type === undefined) && (
                                        <DownloadBoxLabelField />
                                    )}
                                    {(filterValues.type === 'FBM' || filterValues.type === undefined) && (
                                        <DownloadShippingLabelField />
                                    )}
                                    <TextField source="speed" />
                                    <ReferenceField source="accountId" reference="accounts" label="resources.stockoutrequests.fields.account" allowEmpty={true}>
                                        <TextField source="code" />
                                    </ReferenceField>
                                    <NoteField />
                                    <EditButton />
                                </Datagrid>
                            )}
                            {filterValues.status === 'cancelled' && (
                                <Datagrid {...props} 
                                    ids={this.state.cancelled} 
                                    expand={<StockOutEmbedded permissions={permissions} />}
                                >
                                    <TextField source="id" />
                                    {permissions === 'Admin' || permissions === 'WM' ? 
                                    <ReferenceField source="teamId" reference="teams" label="resources.stockoutrequests.fields.team">
                                        <TextField source="name" />
                                    </ReferenceField>
                                     : "" }
                                    <ReferenceField source="appUserId" reference="appusers" label="resources.stockoutrequests.fields.user">
                                        <TextField source="username" />
                                    </ReferenceField>
                                    <ProductReferenceField label="resources.stockoutrequests.fields.product" />
                                    <StatusField />
                                    <TextField source="type" />
                                    <NumberField source="qty" />
                                    <DateField source="stockOutDate" />
                                    <TextField source="shippingCompany" />
                                    {(filterValues.type === 'FBA' || filterValues.type === undefined) && (
                                        <TextField source="barcode" />
                                    )}    
                                    {(filterValues.type === 'FBA' || filterValues.type === undefined) && (
                                        <DownloadBoxLabelField />
                                    )}
                                    {(filterValues.type === 'FBM' || filterValues.type === undefined) && (
                                        <DownloadShippingLabelField />
                                    )}
                                    <TextField source="speed" />
                                    <ReferenceField source="accountId" reference="accounts" label="resources.stockoutrequests.fields.account" allowEmpty={true}>
                                        <TextField source="code" />
                                    </ReferenceField>
                                    <NoteField />
                                    <EditButton />
                                </Datagrid>
                            )}
                        </div>
                    }
                />
            </Fragment>
        );

    }
}

const StockOutRequestList = ({ permissions, ...props }) => (
    <List
        {...props} 
        bulkActionButtons={<StockOutRequestBulkActionButtons permissions={permissions}/>}
        filterDefaultValues={{ status: 'stocking_out' }}
        sort={{ field: 'stockOutDate', order: 'DESC' }}
        perPage={40}
        filters={<StockOutRequestFilter permissions={permissions}/>}
    >
        <TabbedDatagrid permissions={permissions} />
    </List>
);

const enhance = compose(
    translate
);

export default enhance(StockOutRequestList);
