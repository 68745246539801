import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import {translate} from 'react-admin';
import {apiUrl} from '../config';

class MakeProductBarcodeButton extends Component {

    constructor(props) {
	    super(props)
	    this.state = {
	    	asin: this.props.record.asin
	    }
	}
    
    handleClick = (event) => {
        event.stopPropagation();
        const { record } = this.props;
        window.open(apiUrl + '/products/make_v.php?auth='+JSON.parse(localStorage.getItem('lbtoken')).value.id+'&barcode='+record.asin);  
    }

    render() {
        return (
        <div>
            { this.props.record.asin != "" ?  <Button label="MakeProductBarcode" onClick={this.handleClick} color="primary" variant="raised">{this.props.record.status === "printed_boxmark" ? this.props.translate('resources.purchases.actions.viewboxmark') : this.props.translate('resources.spaces.makebarcode')}</Button> :
            null }
        </div>
        );
    }
}

MakeProductBarcodeButton.propTypes = {
    record: PropTypes.object,

};

const enhance = compose(
    translate
);

export default enhance(MakeProductBarcodeButton);