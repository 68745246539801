import StockOutRequestIcon from '@material-ui/icons/LocalShipping';
import StockOutRequestList from './StockOutRequestList';
import StockOutRequestCreate from './StockOutRequestCreate';
import StockOutRequestEdit from './StockOutRequestEdit';
import StockOutRequestShow from './StockOutRequestShow';

export default {
    list: StockOutRequestList,
    create: StockOutRequestCreate,
    edit: StockOutRequestEdit,
    show: StockOutRequestShow,
    icon: StockOutRequestIcon,
};
