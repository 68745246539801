import React from 'react';

const CurrentShippingLabelField = function({ record = {} }) { 
	
	const filename = record.shippingLabel ? record.shippingLabel.substr(record.shippingLabel.lastIndexOf('/') + 1) : '';

    return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        <a href={record.shippingLabel} target="_blank" rel="noopener noreferrer">{filename}</a>
    </div>
    )
};

CurrentShippingLabelField.defaultProps = {
    source: 'shippingLabel',
    addLabel: true,
};

export default CurrentShippingLabelField;