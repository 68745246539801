/* eslint react/jsx-key: off */
import memoize from 'lodash/memoize';

import React from 'react';
import {
    Datagrid,
    EditButton,
    List,
    Responsive,
    ReferenceInput,
    SimpleList,
    TextField,
} from 'react-admin';


const rowClick = memoize(permissions => (id, basePath, record) => {
    return permissions === 'WM'
        ? Promise.resolve('show')
        : Promise.resolve('show');
});

const TeamList = ({ classes, permissions, ...props }) => (
    <List
        {...props}
        sort={{ field: 'name', order: 'DESC' }}
    >
        <Responsive
            small={
                <SimpleList
                    primaryText={record => record.name}
                />
            }
            medium={
                <Datagrid
                    rowClick={rowClick(permissions)}
                >
                    <TextField source="id" />
                    <TextField source="name" />
                    <EditButton />
                </Datagrid>
            }
        />
    </List>
);


export default TeamList;
