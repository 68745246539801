/* eslint react/jsx-key: off */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import {translate} from 'react-admin';
import axios from 'axios';
import {loobackApiUrl} from '../config';

class HandlingButton extends Component {
    
    handleClick = (event) => {
        event.stopPropagation();
        const { record } = this.props;
        const config = {
          headers: {
            'authorization': JSON.parse(localStorage.getItem('lbtoken')).value.id,
            'Content-Type': 'application/json',
          }
        };
        // window.location = '#/stockouts/create?request_id='+record.id+'&product_id='+record.productId+'&qty='+record.qty;    
        axios.patch(loobackApiUrl + '/StockOutRequests/'+record.id, {"status": "handling"}, config).then(function (response) {
            console.log(response);
            window.location = '#/stockoutrequests?filter={"status":"handling"}&order=DESC&page=1&perPage=25&sort=stockOutDate';   
        });
    }

    render() {
        return (
            <Button label="handling" onClick={this.handleClick} color="primary" variant="raised">{this.props.translate('resources.stockoutrequests.actions.handling')}</Button>
        );
    }
}

HandlingButton.propTypes = {
    record: PropTypes.object,

};

const enhance = compose(
    translate
);

export default enhance(HandlingButton);