import TeamIcon from '@material-ui/icons/AccountBox';
import TeamList from './TeamList';
import TeamCreate from './TeamCreate';
import TeamEdit from './TeamEdit';
import TeamShow from './TeamShow';

export default {
    list: TeamList,
    create: TeamCreate,
    edit: TeamEdit,
    show: TeamShow,
    icon: TeamIcon,
};
