import SpaceProductList from './SpaceProductList';
import SpaceProductCreate from './SpaceProductCreate';
import SpaceProductEdit from './SpaceProductEdit';
import SpaceProductShow from './SpaceProductShow';

export default {
    list: SpaceProductList,
    create: SpaceProductCreate,
    edit: SpaceProductEdit,
    show: SpaceProductShow
};
