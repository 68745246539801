import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import SettingsIcon from '@material-ui/icons/Settings';
import { withRouter } from 'react-router-dom';
import {
    translate,
    DashboardMenuItem,
    MenuItemLink,
    Responsive,
    WithPermissions,
} from 'react-admin';

import SubMenu from './SubMenu';

import products from '../products';
import stockinrequests from '../stock_in_requests';
import stockins from '../stock_ins';
import stockoutrequests from '../stock_out_requests';
import stockouts from '../stock_outs';
import users from '../users';
import spaces from '../spaces';
import accounts from '../accounts';
import teams from '../teams';

class Menu extends Component {
    state = {
        menuCatalog: true,
        menuStockOutRequest: true,
        menuCustomers: true,
    };

    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };

    handleToggle = menu => {
        this.setState(state => ({ [menu]: !state[menu] }));
    };

    render() {
        const { onMenuClick, open, logout, translate } = this.props;

        return (
            <div>
                {' '}
                <DashboardMenuItem onClick={onMenuClick} />

                <MenuItemLink
                    to={`/products`}
                    primaryText={translate(`resources.products.menu`, {
                        smart_count: 2,
                    })}
                    leftIcon={<products.icon />}
                    onClick={onMenuClick}
                />

                <MenuItemLink
                    to={`/stockinrequests`}
                    primaryText={translate(`resources.stockinrequests.menu`, {
                        smart_count: 2,
                    })}
                    leftIcon={<stockinrequests.icon />}
                    onClick={onMenuClick}
                />
                <WithPermissions
                    render={({ permissions }) => (
                        permissions === 'WM' || permissions === 'Admin'
                            ? <MenuItemLink
                                to={`/stockins`}
                                primaryText={translate(`resources.stockins.menu`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<stockins.icon />}
                                onClick={onMenuClick}
                            />
                            : null
                    )}
                />
                <SubMenu
                   name={translate(`resources.stockoutrequests.menu`, {
                    smart_count: 2,
                })}
                   handleToggle={() => this.handleToggle('menuStockOutRequest')}
                   isOpen={this.state.menuStockOutRequest}
                   sidebarIsOpen={open}
                   icon={<stockoutrequests.icon />}
              
                >
                <MenuItemLink
                    to={`/stockoutrequests?filter={"status":"stocking_out"}&order=DESC&page=1&perPage=40&sort=stockOutDate`}
                    primaryText={translate(`resources.stockoutrequests.all`, {
                        smart_count: 2,
                    })}
                    leftIcon={<stockoutrequests.icon />}
                    onClick={onMenuClick}
                />                             
                <MenuItemLink
                    to={`/stockoutrequests?filter={"status":"stocking_out","type":"FBA"}&order=DESC&page=1&perPage=40&sort=stockOutDate`}
                    primaryText={translate(`resources.stockoutrequests.fba`, {
                        smart_count: 2,
                    })}
                    leftIcon={<stockoutrequests.icon />}
                    onClick={onMenuClick}
                />
                <MenuItemLink
                    to={`/stockoutrequests?filter={"status":"stocking_out","type":"FBM"}&order=DESC&page=1&perPage=40&sort=stockOutDate`}
                    primaryText={translate(`resources.stockoutrequests.fbm`, {
                        smart_count: 2,
                    })}
                    leftIcon={<stockoutrequests.icon />}
                    onClick={onMenuClick}
                />                
                </SubMenu>  
                <WithPermissions
                    render={({ permissions }) => (
                        permissions === 'WM' || permissions === 'Admin'
                            ? <MenuItemLink
                                to={`/stockouts`}
                                primaryText={translate(`resources.stockouts.menu`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<stockouts.icon />}
                                onClick={onMenuClick}
                            />
                            : null
                    )}
                />
                <WithPermissions
                    render={({ permissions }) => (
                        permissions === 'Admin' || permissions === 'PM'
                            ? <MenuItemLink
                                to={`/accounts`}
                                primaryText={translate(`resources.accounts.menu`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<accounts.icon />}
                                onClick={onMenuClick}
                            />
                            : null
                    )}
                />
                <WithPermissions
                    render={({ permissions }) => (
                        permissions === 'Admin'
                            ? <MenuItemLink
                                to={`/teams`}
                                primaryText={translate(`resources.teams.menu`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<teams.icon />}
                                onClick={onMenuClick}
                            />
                            : null
                    )}
                />
                <WithPermissions
                    render={({ permissions }) => (
                        permissions === 'Admin'
                            ? <MenuItemLink
                                to={`/appusers`}
                                primaryText={translate(`resources.appusers.menu`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<users.icon />}
                                onClick={onMenuClick}
                            />
                            : null
                    )}
                />
                
                <WithPermissions
                    render={({ permissions }) => (
                        permissions === 'WM'
                            ? <MenuItemLink
                                to={`/spaces`}
                                primaryText={translate(`resources.spaces.menu`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<spaces.icon />}
                                onClick={onMenuClick}
                            />
                            : null
                    )}
                />     

                <WithPermissions
                    render={({ permissions }) => (
                        permissions === 'WM'
                            ? <MenuItemLink
                                to={`/spacesproducts`}
                                primaryText={translate(`resources.spacesproducts.menu`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<spaces.icon />}
                                onClick={onMenuClick}
                            />
                            : null
                    )}
                />                               

                <Responsive
                    xsmall={
                        <MenuItemLink
                            to="/configuration"
                            primaryText={translate('pos.configuration')}
                            leftIcon={<SettingsIcon />}
                            onClick={onMenuClick}
                        />
                    }
                    medium={null}
                />
                <Responsive
                    small={logout}
                    medium={null} // Pass null to render nothing on larger devices
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    theme: state.theme,
    locale: state.i18n.locale,
});

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
    translate
);

export default enhance(Menu);