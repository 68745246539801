import React, { Component }  from 'react';
import compose from 'recompose/compose';
import {translate} from 'react-admin';

class DownloadShippingLabelField extends Component {

	handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const { record } = this.props;
        window.open(record.shippingLabel.replace("s3.amazonaws.com", "s3.amazonaws.com"), '_blank');    
    }

    getBoxLabelFile = () => {
        const { record } = this.props;
        if(typeof record.shippingLabel === 'string') {
            return record.shippingLabel.substr(record.shippingLabel.lastIndexOf('.') + 1)
        } else {
            return "";
        }
    }
    
    render() {
	    return (
	    	<a href="" label="download_boxbabel" onClick={this.handleClick} color="primary" variant="raised">{this.getBoxLabelFile()}</a>
	    )
	}
};

DownloadShippingLabelField.defaultProps = {
    source: 'shippingLabel',
    addLabel: true,
};

const enhance = compose(
    translate
);

export default enhance(DownloadShippingLabelField);