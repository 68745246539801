/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import ProductReferenceField from '../products/ProductReferenceField';
import { Show, SimpleShowLayout, TextField, ReferenceField} from 'react-admin'; // eslint-disable-line import/no-unresolved


const SpaceProductShow = ({ permissions, ...props }) => (
    <Show {...props}>
        <SimpleShowLayout>
                <ProductReferenceField />               
                    <ReferenceField source="spaceId" reference="spaces" >
                        <TextField source="name" />
                    </ReferenceField>   
                <TextField source="qty" />

        </SimpleShowLayout>
    </Show>
);

SpaceProductShow.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default SpaceProductShow;
