/* eslint react/jsx-key: off */
import ProductIcon from '@material-ui/icons/Storage';
import memoize from 'lodash/memoize';
// import StockInListEmbedded from './StockInListEmbedded';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import compose from 'recompose/compose';
import StockInButton from './StockInButton';
import ProductReferenceField from '../products/ProductReferenceField';
import StockInEmbedded from './StockInEmbedded';
import StatusField from './StatusField';
import WaybillField from './WaybillField';
import { BulkDeleteButton } from 'react-admin';
import MovetoStockInButton from './MovetoStockInButton';
import React, { Fragment } from 'react';
import {
    Datagrid,
    EditButton,
    Filter,
    List,
    Responsive,
    ReferenceInput,
    AutocompleteInput,
    TextInput,
    TextField,
    NumberField,
    ReferenceField,
    DateField,
    translate,
} from 'react-admin';

import MobileGrid from '../layout/MobileGrid';

export const UserIcon = ProductIcon;


const StockInRequestBulkActionButtons = ({ permissions, ...props }) => (
    <Fragment>
        { permissions === "Admin" || permissions === 'WM' ? <MovetoStockInButton label="StockIn" {...props} /> : null} 
        {/* Add the default bulk delete action */}        
        { permissions === "Admin" || permissions === 'WM' ? <BulkDeleteButton {...props} /> : null}
    </Fragment>
);


const StockInRequestFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <ReferenceInput source="productId" reference="products" label="resources.stockinrequests.fields.product" alwaysOn>
            <AutocompleteInput
                optionText={choice =>
                    `${choice.name}`
                }
            />
        </ReferenceInput>
        <ReferenceInput source="appUserId" reference="appusers" label="resources.stockinrequests.fields.user" alwaysOn>
            <AutocompleteInput
                optionText={choice =>
                    `${choice.username}`
                }
            />
        </ReferenceInput>
        { permissions === "Admin" || permissions === "WM" ?
            <ReferenceInput source="teamId" reference="teams" label="resources.stockoutrequests.fields.team" alwaysOn  perPage={999}>
                <AutocompleteInput
                    optionText={choice =>
                        `${choice.name}`
                    }
                />
            </ReferenceInput>
            :
            null
        }
    </Filter>
);

const rowClick = memoize(permissions => (id, basePath, record) => {
    return permissions === 'WM'
        ? Promise.resolve('edit')
        : Promise.resolve('edit');
});

class TabbedDatagrid extends React.Component {

    state = { stocking_in: [], stocked_in: [], cancelled: [] };

    static getDerivedStateFromProps(props, state) {
        if (props.ids !== state[props.filterValues.status]) {
            return { ...state, [props.filterValues.status]: props.ids };
        }
        return null;
    }

    handleChange = (event, value) => {
        const { filterValues, setFilters } = this.props;
        setFilters({ ...filterValues, status: value });
    };

    render() {

        const { classes, filterValues, translate, permissions, ...props } = this.props;

        return (

            <Fragment>
                <Tabs
                    fullWidth
                    centered
                    value={filterValues.status}
                    indicatorColor="primary"
                    onChange={this.handleChange}
                >
                        <Tab
                            key="stocking_in"
                            label={translate(`resources.stockinrequests.tab.stocking_in`)}
                            value="stocking_in"
                        />
                        <Tab
                            key="stocked_in"
                            label={translate(`resources.stockinrequests.tab.stocked_in`)}
                            value="stocked_in"
                        />
                        <Tab
                            key="cancelled"
                            label={translate(`resources.stockinrequests.tab.cancelled`)}
                            value="cancelled"
                        />
                </Tabs>
                <Divider />
                <Responsive
                    xsmall={
                        <MobileGrid
                            {...props}
                            ids={this.state[filterValues.status]}
                        />
                    }
                    medium={
                        <div>
                            {filterValues.status === 'stocking_in' && (
                                <Datagrid {...props} 
                                    ids={this.state.stocking_in} 
                                    // rowClick={rowClick(permissions)}
                                    expand={<StockInEmbedded permissions={permissions} />}
                                >
                                    <TextField source="id" />
                                    {permissions === 'Admin' || permissions === 'WM' ? 
                                    <ReferenceField source="teamId" reference="teams" label="resources.stockinrequests.fields.team">
                                        <TextField source="name" />
                                    </ReferenceField>
                                     : "" }
                                    <ReferenceField source="appUserId" reference="appusers" label="resources.stockinrequests.fields.user">
                                        <TextField source="username" />
                                    </ReferenceField>
                                    <ProductReferenceField label="resources.stockinrequests.fields.product" />
                                    <StatusField />
                                    <NumberField source="boxes" />
                                    <NumberField source="qtyInBox" />
                                    <NumberField source="weightPerBox" />
                                    <NumberField source="qtyInTailBox" />
                                    <NumberField source="qty" />
                                    <NumberField source="qtyPending" />
                                    <WaybillField />
                                    <DateField source="estArrivalDate" />
                                    <TextField source="shippingCompany" />
                                    <EditButton />
                                    {permissions === 'WM' ? <StockInButton /> : "" }
                                </Datagrid>
                            )}
                            {filterValues.status === 'stocked_in' && (
                                <Datagrid {...props} 
                                    ids={this.state.stocked_in} 
                                    // rowClick={rowClick(permissions)}
                                    expand={<StockInEmbedded permissions={permissions} />}
                                >
                                    <TextField source="id" />
                                    {permissions === 'Admin' || permissions === 'WM' ? 
                                    <ReferenceField source="teamId" reference="teams" label="resources.stockinrequests.fields.team">
                                        <TextField source="name" />
                                    </ReferenceField>
                                     : "" }
                                    <ReferenceField source="appUserId" reference="appusers" label="resources.stockinrequests.fields.user">
                                        <TextField source="username" />
                                    </ReferenceField>
                                    <ProductReferenceField label="resources.stockinrequests.fields.product" />
                                    <StatusField />
                                    <NumberField source="boxes" />
                                    <NumberField source="qtyInBox" />
                                    <NumberField source="weightPerBox" />
                                    <NumberField source="qtyInTailBox" />
                                    <NumberField source="qty" />
                                    <NumberField source="qtyPending" />
                                    <WaybillField />
                                    <DateField source="estArrivalDate" />
                                    <TextField source="shippingCompany" />
                                    <EditButton />
                                </Datagrid>
                            )}
                            {filterValues.status === 'cancelled' && (
                                <Datagrid {...props} 
                                    ids={this.state.cancelled} 
                                    // rowClick={rowClick(permissions)}
                                    expand={<StockInEmbedded permissions={permissions} />}
                                >
                                    <TextField source="id" />
                                    {permissions === 'Admin' || permissions === 'WM' ? 
                                    <ReferenceField source="teamId" reference="teams" label="resources.stockinrequests.fields.team">
                                        <TextField source="name" />
                                    </ReferenceField>
                                     : "" }
                                    <ReferenceField source="appUserId" reference="appusers" label="resources.stockinrequests.fields.user">
                                        <TextField source="username" />
                                    </ReferenceField>
                                    <ProductReferenceField label="resources.stockinrequests.fields.product" />
                                    <StatusField />
                                    <NumberField source="boxes" />
                                    <NumberField source="qtyInBox" />
                                    <NumberField source="weightPerBox" />
                                    <NumberField source="qtyInTailBox" />
                                    <NumberField source="qty" />
                                    <NumberField source="qtyPending" />
                                    <WaybillField />
                                    <DateField source="estArrivalDate" />
                                    <TextField source="shippingCompany" />
                                    <EditButton />
                                </Datagrid>
                            )}
                        </div>
                    }
                />
            </Fragment>
        );

    }
}

const StockInRequestList = ({ permissions, ...props }) => (
    <List
        {...props}
        bulkActionButtons={<StockInRequestBulkActionButtons permissions={permissions}/>}
        filterDefaultValues={{ status: 'stocking_in' }}        
        sort={{ field: 'estArrivalDate', order: 'DESC' }}
        perPage={25}
        filters={<StockInRequestFilter permissions={permissions}/>}
    >
        <TabbedDatagrid permissions={permissions} />
    </List>
);

const enhance = compose(
    translate
);

export default enhance(StockInRequestList);
