import React from 'react';

const CurrentBarcodeLabelField = function({ record = {} }) { 
	
	const filename = record.barcodeLabel ? record.barcodeLabel.substr(record.barcodeLabel.lastIndexOf('/') + 1) : '';

    return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        <a href={record.barcodeLabel} target="_blank" rel="noopener noreferrer">{filename}</a>
    </div>
    )
};

CurrentBarcodeLabelField.defaultProps = {
    source: 'barcodeLabel',
    addLabel: true,
};

export default CurrentBarcodeLabelField;