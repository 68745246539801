import React, { Component }  from 'react';
import compose from 'recompose/compose';
import {translate} from 'react-admin';
import {apiUrl} from '../config';

class DownloadStockoutLabelField extends Component {
    constructor(props) {
        super(props)
    }

    generateUrl = () => {
        const {record} = this.props;
        var token_id = JSON.parse(localStorage.getItem('lbtoken')).value.id;

        return apiUrl + "/makelabel/index.php?id="+record.id+"&productId="+record.productId+"&qty="+record.qty+"&barcode="+record.barcode + "&auth=" +token_id;
    }

    render() {
	    return (
            <a href={this.generateUrl()} target="_blank">ship order</a>
	    )
	}
};


const enhance = compose(
    translate
);

export default enhance(DownloadStockoutLabelField);